import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import AmplifyVue from '@aws-amplify/ui-vue'
import { Amplify } from 'aws-amplify';
import { awsconfig } from './aws-exports';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
Amplify.configure(awsconfig);
const app = createApp(App);
app.use(router);
app.use(AmplifyVue);
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')


<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-message-provider>

      <div class="app-container">
        <!-- Header -->
        <header class="app-header">
          <router-link to="/">
            <img src="@/assets/logo_new.svg" alt="PICKGEO Location Intelligence" class="logo" />
          </router-link>
          <nav>
            <NButton strong quaternary type="primary" @click="navigate('/')">
              APIs
            </NButton>
            <NButton strong quaternary type="primary" @click="navigate('/cost-calculator')">
              Calculadora de coste
            </NButton>
            <a href="https://api.pickgeo.com/api/geoapi/docs" target="_blank" style="margin-left: 0">
              <NButton strong quaternary type="primary">
                Docs
              </NButton>
            </a>
            <NButton v-if="this.auth.route === 'authenticated'" strong type="primary" @click="navigate('/dashboard')">
              Dashboard
            </NButton>
            <NButton v-else strong type="primary" @click="navigate('/dashboard')">
              Acceder
            </NButton>
            <NButton v-if="this.auth.route === 'authenticated'" strong type="secondary" @click="auth.signOut">
              Salir
            </NButton>
          </nav>
        </header>

        <!-- Content Area -->
        <main class="app-content">
          <router-view />
        </main>

        <!-- Footer -->
        <footer class="app-footer">
          © 2023 Pickgeo Location Intelligence
        </footer>
      </div>
    </n-message-provider>

  </n-config-provider>
</template>

<script>
import {
  NButton,
  NConfigProvider,
  NMessageProvider,
} from 'naive-ui'

import { useAuthenticator } from '@aws-amplify/ui-vue';

export default {
  name: 'App',
  components: {
    NButton,
    NConfigProvider,
    NMessageProvider,
  },
  methods: {
    navigate(path) {
      this.$router.push(path);
    }
  },
  data() {
    const auth = useAuthenticator();
    return {
      themeOverrides: {
        common: {
          primaryColor: "#203864",
          primaryColorHover: "#8faadc",
          secondayColor: "#2f5597"
        },
      },
      auth: auth
    }
  }
}
</script>

<style scoped>
.app-header {
  display: flex;
  flex-direction: column;
  /* stack children vertically */
  align-items: center;
  /* center items horizontally */
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #b9b9b9;
  margin-top: 10px;
  text-align: center;
  /* align text items in the center */
}

.logo {
  height: 80px;
  width: auto;
  margin-bottom: 20px;
  /* added some margin for spacing between logo and nav */
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  /* center the nav items horizontally */
  flex-wrap: wrap;
  gap: 5px;
}

nav>*+* {
  margin-left: 20px;
}

.app-footer {
  margin-top: 0px;
  text-align: center;
  color: #888;
  font-size: 14px;
  padding-bottom: 20px;
}

button {
  margin: 0;
}
</style>

<style>
html,
body,
#app {
  height: 100%;
  /* Asegurarse de que ocupen todo el alto */
  background-color: #f3f7fa;
  /* Color gris/azulado ligeramente claro, puedes ajustar este valor según tus preferencias */
  margin: 0;
  /* Reiniciar márgenes */
}

.app-content {
  flex: 1;
  margin-top: 20px;
  height: 100%
}

.app-container {
  /* max-width: 1280px; */
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  height: 100%
}

@media (min-width: 768px) {
  .app-container {
    width: 80%;
  }
}
</style>
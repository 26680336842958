<template>
  <div v-if="(this.auth.route === 'authenticated' && this.isAdmin)" class="apis-view">
    <div class="card_raw">
      <section class="section">
        <div class="header-container">
          <h1>ADMIN PANEL</h1>
          <n-button strong type="primary" @click="createUserModal = true">
            Crear nuevo usuario
          </n-button>
        </div>
      </section>
    </div>

    <!-- Sección 1: APIs de Pickgeo -->
    <div class="card">
      <section class="section">
        <h1>Listado de usuarios</h1>
        <n-input class="mb-10" v-model:value="searchTermUserList" type="text" placeholder="Busca nombres de usuario" />
        <n-data-table :columns="this.usercolumns" :data="this.filteredUsers" />
      </section>
    </div>

    <!-- Sección 1: APIs de Pickgeo -->
    <div class="card">
      <section class="section">
        <h1>Uso de las APIs</h1>
        <n-input class="mb-10" v-model:value="searchTermUserUsage" type="text" placeholder="Busca nombres de usuario" />
        <n-data-table :columns="this.usageColumns" :data="this.filteredUsage" />
      </section>
    </div>
  </div>
  <div v-else>
    <h1>Upsss</h1>
    <p>No encontramos la página que estás buscando</p>
  </div>

  <n-modal v-model:show="createUserModal" preset="card" title="Crear usuario" size="huge" style="width: 600px">
    <n-input v-model:value="newUserData.email" placeholder="Email" />
    <template #footer>
      <n-button type="primary" @click="createUser" :disabled="this.isValidEmail(this.newUserData.email) ? false : true">
        Guardar
      </n-button>
    </template>
  </n-modal>
</template>
  
<script>
import { adminApiService } from '../services/api.service'
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { h } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  NDataTable,
  NInput,
  NModal,
  NButton,
  NTag
} from 'naive-ui'

export default {
  name: 'AdminView',
  components: {
    NDataTable,
    NInput,
    NModal,
    NButton,
  },
  data() {
    const auth = useAuthenticator();
    const usageColumns = [
      {
        title: "Usuario",
        key: "user"
      },
      {
        title: "Mes",
        key: "name"
      },
      {
        title: "Llamadas",
        key: "requests"
      }
    ];
    const usercolumns = [
      {
        title: "Usuario",
        key: "user"
      },
      {
        title: "Límite de uso",
        key: "limit",
        render(row) {
          let newLimit = 0;
          const formatter = new Intl.NumberFormat('es-ES');
          return h('div', {}, [
            h(
              NButton,
              {
                circle: true,
                tertiary: true,
                type: "primary",
                onClick: () => {
                  if (row.isLimitEditing) {
                    row.limit = newLimit;
                    adminApiService.adminSetUserLimit(row.key, row.limit)
                  }
                  row.isLimitEditing = !row.isLimitEditing;
                },
                style: {
                  marginRight: '10px'
                }
              },
              {
                default: () => h(FontAwesomeIcon, {
                  icon: row.isLimitEditing ? ['fas', 'save'] : ['fas', 'edit']
                })
              }
            ),
            row.isLimitEditing ?
              h(NInput, {
                modelValue: newLimit,
                'onInput': (value) => {
                  newLimit = value;
                },
                type: 'text',
                placeholder: 'Límite',
                style: {
                  width: '80px'
                }
              }) :
              (row.limit == 0 | row.limit == null) ? 'Sin límite' : formatter.format(row.limit),
            row.isLimitEditing ?
              h(NButton,
                {
                  circle: true,
                  quaternary: true,
                  type: "error", // Assuming error type gives it a red color or choose what fits best
                  onClick: () => {
                    row.isLimitEditing = false; // Set edit mode to false
                  },
                  style: {
                    marginLeft: '2px'
                  }
                },
                {
                  default: () => h(FontAwesomeIcon, {
                    icon: ['fas', 'times']  // "times" is the X icon in Font Awesome
                  })
                }
              ) : null,
          ])
        }
      },
      {
        title: "Municipios permitidos",
        key: "allowed_municipalities",
        render(row) {
          let newMunicipalities = null;
          return h('div', {}, [
            h(
              NButton,
              {
                circle: true,
                tertiary: true,
                type: "primary",
                onClick: () => {
                  if (row.isMunicipalityEditing) {
                    row.allowed_municipalities = newMunicipalities;
                    adminApiService.adminSetUserMunicipalities(row.key, row.allowed_municipalities)
                  }
                  row.isMunicipalityEditing = !row.isMunicipalityEditing;
                },
                style: {
                  marginRight: '10px'
                }
              },
              {
                default: () => h(FontAwesomeIcon, {
                  icon: row.isMunicipalityEditing ? ['fas', 'save'] : ['fas', 'edit']
                })
              }
            ),
            row.isMunicipalityEditing ?
              h(NInput, {
                modelValue: newMunicipalities,
                'onInput': (value) => {
                  newMunicipalities = value;
                },
                type: 'text',
                placeholder: 'Límite',
                style: {
                  width: '80px'
                }
              }) :
              (row.allowed_municipalities == null) ? 'Sin restricciones' : row.allowed_municipalities,
            row.isMunicipalityEditing ?
              h(NButton,
                {
                  circle: true,
                  quaternary: true,
                  type: "error", // Assuming error type gives it a red color or choose what fits best
                  onClick: () => {
                    row.isMunicipalityEditing = false; // Set edit mode to false
                  },
                  style: {
                    marginLeft: '2px'
                  }
                },
                {
                  default: () => h(FontAwesomeIcon, {
                    icon: ['fas', 'times']  // "times" is the X icon in Font Awesome
                  })
                }
              ) : null,
          ])
        }
      },
      {
        title: 'Estado',
        key: 'active',
        render(row) {
          return h(
            NTag,
            {
              style: {
                marginRight: '6px'
              },
              type: row.active ? 'success' : 'error',
              bordered: false
            },
            {
              default: () => row.active ? 'Activo' : 'Inactivo'
            }
          )
        }
      },
      {
        title: 'Acciones',
        key: 'actions',
        render(row) {
          return h(
            NButton,
            {
              strong: true,
              tertiary: true,
              size: 'small',
              onClick: () => {
                adminApiService.adminSetUserStatus(row.key, !row.active)
                row.active = !row.active;
              }
            },
            { default: () => row.active ? 'Desactivar' : 'Activar' }
          )
        }
      },
      {
        title: 'Token',
        key: 'api_token',
        width: '200px',
        render(row) {
          return h(
            'span',
            {
              style: {
                fontSize: '10px',
              }
            },
            row.api_token
          )
        }
      }
    ];
    return {
      auth: auth,
      usercolumns: usercolumns,
      usageColumns: usageColumns,
      searchTermUserUsage: '',
      searchTermUserList: '',
      isAdmin: false,
      usage: [],
      users: [],
      createUserModal: false,
      newUserData: {
        email: "",
      },
      rules: {
        email: {
          required: true,
          message: 'Añade un email',
          trigger: 'blur'
        }
      },
    }
  },
  mounted() {
    if (this.auth.route === 'authenticated') {
      (async () => {
        this.isAdmin = await adminApiService.adminGetIsAdmin();
        if (this.isAdmin) {
          this.usage = await adminApiService.adminGetUsage();
          this.users = await adminApiService.adminGetUserList();
          console.log(this.users)
        }
      })();
    }
  },
  watch: {
    async 'auth.route'(newRoute) {
      if (newRoute === 'authenticated') {
        this.isAdmin = await adminApiService.adminGetIsAdmin();
        if (this.isAdmin) {
          this.usage = await adminApiService.adminGetUsage();
          this.users = await adminApiService.adminGetUserList();
        }
      }
    }
  },
  computed: {
    filteredUsage() {
      try {
        return this.usage.filter(item => item.user.toLowerCase().includes(this.searchTermUserUsage.toLowerCase()))
      }
      catch {
        return []
      }
    },
    filteredUsers() {
      try {
        return this.users.filter(item => item.user.toLowerCase().includes(this.searchTermUserList.toLowerCase()))
      }
      catch {
        return []
      }
    },
  },
  methods: {
    async createUser() {
      adminApiService.adminCreateUser(this.newUserData.email)
      this.usage = await adminApiService.adminGetUsage();
      this.users = await adminApiService.adminGetUserList();
      this.createUserModal = false
    },
    formatNumber(value) {
      const formatter = new Intl.NumberFormat('es-ES');
      return formatter.format(value);
    },
    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    }
  }
}
</script>

<style scoped>
.section {
  margin-bottom: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.card {
  background-color: #ffffff;
  /* Fondo blanco */
  box-shadow: 0px 4px 50px 15px hsla(219, 52%, 26%, 0.1);
  /* Sombra más extendida */
  margin-bottom: 30px;
  /* Espacio entre tarjetas */
  padding: 20px;
  /* Espacio interno */
  border-radius: 8px;
  /* Bordes redondeados */
}

.card_raw {
  margin-bottom: 10px;
  /* Espacio entre tarjetas */
  padding: 20px;
  /* Espacio interno */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* This will vertically align the title and the button */
}
</style>
<template>
    <div class="authenticator-wrapper">
        <h2 class="login-title">Acceder</h2>

        <authenticator class="authenticator" :hide-sign-up="true">
        </authenticator>

        <div class="info-box">
            ¿Todavía no tienes una cuenta para utilizar la API de pickgeo?
            <br>
            Escribe un email a <strong>contact@pickgeo.com</strong>
        </div>
    </div>
</template>
  
<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';

I18n.putVocabularies(translations);
I18n.setLanguage('es');

export default {
    name: 'LoginComponent',
    components: {
        Authenticator
    },
};
</script>

<style>
.authenticator-wrapper {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}

.authenticator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* This can vertically center contents inside the authenticator if needed */
}

.info-box {
    margin-top: 40px;
    /* Adjust as needed */
    padding: 20px;
    /* Adjust as needed */
    border: 1px solid #ccc;
    /* Adjust border style/color as needed */
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.login-title {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -10px;
    /* Adjust as needed */
    font-weight: bold;
    font-size: 3rem;
}

[data-amplify-authenticator] {
    --amplify-components-button-primary-background-color: #203864;
    --amplify-components-button-primary-color: #FFF;
    --amplify-components-button-primary-hover-background-color: #2f5597;
    --amplify-components-button-primary-hover-color: #FFF;
    --amplify-components-button-link-color: #203864;
    --amplify-components-button-link-hover-color: #203864;
    --amplify-components-button-link-hover-background-color: #d8e1f1;
    --amplify-components-passwordfield-button-color: #203864;
    --amplify-components-passwordfield-button-hover-background-color: #ebeff7;
    --amplify-components-passwordfield-button-hover-border-color: #203864;
    --amplify-components-passwordfield-button-hover-color: #203864;
}

@media (min-width: 768px) {
    .authenticator-wrapper {
        width: 80%;
    }

    .login-title {
        margin-top: 0px;
    }
}
</style>
import axios from 'axios'
import { useAuthenticator } from '@aws-amplify/ui-vue';

const backendBaseUrl = process.env.VUE_APP_BACKEND_URL || (window.location.origin + '/api');

class DefaultApiService {

    constructor() {
        this.api = axios.create({
            baseURL: backendBaseUrl
        }),
            this.auth = useAuthenticator();
    }

    async getApisDetails() {
        const response = await this.api.get('/admin/pricings/current/apis', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        })
        return response.data.map(item => ({ ...item, maxCalls: 100000, calls: 10000, step: 100 }));
    }

}

class UsersApiService {

    constructor() {
        this.api = axios.create({
            baseURL: backendBaseUrl
        }),
            this.auth = useAuthenticator();
    }

    async userGetMonthlyUsage() {
        const response = await this.api.get('/admin/users/current/dashboard', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        })
        return response.data
    }

    async userGetToken() {
        const response = await this.api.get('/admin/users/current/token', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        })
        return response.data
    }

}

class AdminApiService {

    constructor() {
        this.api = axios.create({
            baseURL: backendBaseUrl
        }),
            this.auth = useAuthenticator();
    }

    async adminGetIsAdmin() {
        try {
            const response = await this.api.get('/admin/users/isadmin', {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
                }
            })
            return response.data
        }
        catch {
            return false
        }
    }

    async adminGetUsage() {
        const response = await this.api.get('/admin/users/dashboard/all', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        })
        return response.data
    }

    async adminGetUserList() {
        const response = await this.api.get('/admin/users/all', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        })
        return response.data
    }

    adminSetUserStatus(userId, status) {
        const requestBody = {
            active: status ? 1 : 0
        };

        this.api.put(`/admin/users/${userId}`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        });
    }

    adminSetUserLimit(userId, limit) {
        if (limit == null) {
            limit = 0;
        }
        const requestBody = {
            usage_limit: limit
        };

        this.api.put(`/admin/users/${userId}`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        });
    }

    adminSetUserMunicipalities(userId, municipalities) {
        if (municipalities == null) {
            municipalities = "-1";
        }
        const requestBody = {
            allowed_municipalities: municipalities
        };

        this.api.put(`/admin/users/${userId}`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        });
    }

    adminCreateUser(email) {
        const requestBody = {
            mail: email
        };

        this.api.post(`/admin/users`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.auth.user?.signInUserSession.accessToken.jwtToken}`
            }
        });

    }

}

export const usersApiService = new UsersApiService();
export const adminApiService = new AdminApiService();
export const defaultApiService = new DefaultApiService();
import { createRouter, createWebHistory } from 'vue-router'
import ApisView from '../views/ApisView.vue'
import Error404Page from '../views/Error404Page.vue'
import CostCalculatorView from '../views/CostCalculatorView.vue'
import DashboardView from '../views/DashboardView.vue'
import AdminView from '../views/AdminView.vue'
import DocsView from '../views/DocsView.vue'


const routes = [
  {
    path: '/',
    component: ApisView,
    meta: { requiresAuth: false }
  },
  {
    path: '/cost-calculator',
    component: CostCalculatorView,
  },
  {
    path: '/dashboard',
    component: DashboardView,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404Page,
  },
  {
    path: '/admin',
    component: AdminView,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
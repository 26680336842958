<template>
    <div>
        <h1>Upsss</h1>
        <p>No encontramos la página que estás buscando</p>
    </div>
</template>


<script>

export default {
    name: 'Error404Page',
    components: {
    },
}

</script>
<style scoped></style>
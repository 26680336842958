<template>
  <div class="apis-view">

    <!-- Introducción -->
    <div class="card_raw">
      <section class="introduction">
        <h1>Estimación de costes</h1>
        <p>
          Aquí podrá estimar cual va a ser el gasto en función de las llamadas que vaya a hacer a la API de pickgeo. Estos costes son orientativos, póngase en contacto con contact@pickgeo.com para obtener una oferta personaliazda.
        </p>
      </section>
    </div>
    <!-- Sección 1: APIs de Pickgeo -->
    <div class="card">
      <section class="introduction">
        <div class="header-container">
          <h2>Calculadora de costes en función del uso de las APIs</h2>
          <div class="button-group">
            <NButton @click="resetApiCalls" color="#809fd0" round style="margin-right: 10px;">Volver a empezar</NButton>
            <NButton @click="resetApiCallsZero" color="#8c94ac" round style="margin-right: 10px;">Poner a cero</NButton>
            <a href="https://api.pickgeo.com/api/geoapi/docs" target="_blank" style="margin-left: 0">
              <NButton strong type="primary" tertiary>Ir a los DOCS</NButton>
            </a>
          </div>
        </div>
        <p>
          Introduzca el uso estimado de las distintas APIs para calcular el potencial coste en el que incurriría:
        </p>
        <span v-if="this.screenWidth > 768">
          <div class="api-cost">
            <div class="column name"><strong>API</strong></div>
            <div class="column slider"><strong>Llamadas</strong></div>
            <div class="column calls"><strong></strong></div>
            <div class="column cost-per-call"><strong>€/llamada</strong></div>
            <div class="column cost"><strong>Subtotal (€)</strong></div>
          </div>
          <div class="api-cost" v-for="api in apis" :key="api.name">
            <div class="column name">{{ api.name }}</div>
            <div class="column slider"><n-slider v-model:value="api.calls" :max="api.maxCalls" :step="api.step" /></div>
            <div class="column calls">{{ this.formatNumber(api.calls) }}</div>
            <div class="column cost-per-call">{{ this.formatNumber(api.costPerCall) }}€</div>
            <div class="column cost">{{ this.formatNumber(Math.round(api.costPerCall * api.calls, 2)) }}€</div>
          </div>
        </span>

        <span v-if="this.screenWidth <= 768">
          <div class="api-cost" v-for="api in apis" :key="api.name">
            <span class="mobile-column">
              <div class="column name"><strong>API</strong></div>
              <div class="column name">{{ api.name }}</div>
            </span>
            <span class="mobile-column">
              <div class="column slider"><strong>Llamadas</strong></div>
              <div class="column slider"><n-slider v-model:value="api.calls" :max="api.maxCalls" :step="api.step" />
              </div>
              <div class="column calls">{{ this.formatNumber(api.calls) }}</div>
            </span>
            <span class="mobile-column">
              <div class="column cost-per-call"><strong>€/llamada</strong></div>
              <div class="column cost-per-call">{{ this.formatNumber(api.costPerCall) }}€</div>
            </span>
            <span class="mobile-column">
              <div class="column cost"><strong>Subtotal (€)</strong></div>
              <div class="column cost">{{ this.formatNumber(Math.round(api.costPerCall * api.calls, 2)) }}€</div>
            </span>
            <hr>
          </div>
        </span>

        <div class="total-cost">
          Coste total: {{ this.formatNumber(Math.round(totalCost, 2)) }}€
        </div>
      </section>

    </div>


  </div>
</template>

<script>
import { NSlider, NButton } from 'naive-ui'
import { defaultApiService } from '../services/api.service'

export default {
  name: 'CostCalculatorView',
  components: {
    NSlider,
    NButton
  },
  methods: {
    resetApiCalls() {
      this.apis.forEach(api => api.calls = 10000);
    },
    resetApiCallsZero() {
      this.apis.forEach(api => api.calls = 0);
    },
    navigate(path) {
      this.$router.push(path);
    },
    formatNumber(value) {
      const formatter = new Intl.NumberFormat('es-ES');
      return formatter.format(value);
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    },
  },
  data() {
    return {
      apis: [],
      screenWidth: window.innerWidth
    }
  },
  mounted() {
    (async () => {
      this.apis = await defaultApiService.getApisDetails();
    })();
    window.addEventListener("resize", this.handleResize)
  },
  beforeUnmount() {
    window.addEventListener("resize", this.handleResize)
  },
  computed: {
    totalCost() {
      return this.apis.reduce((acc, api) => acc + api.calls * api.costPerCall, 0)
    }
  },
}
</script>

<style scoped>
.api-cost {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column; */
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.apis-view {
  padding: 20px 0;
  font-family: 'Ubuntu', sans-serif;
}

.introduction {
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* This will vertically align the title and the button */
  flex-wrap: wrap;
}

.column {
  flex: 1;
  text-align: center;
}

.column.name {
  flex: 1;
  /* Si deseas que el nombre de la API tenga más espacio */
  text-align: center;
  /* Alinear el nombre a la izquierda */
}



.column.slider {
  flex: 3;
  /* Si deseas que el slider tenga más espacio */
}

.card {
  background-color: #ffffff;
  /* Fondo blanco */
  box-shadow: 0px 4px 50px 15px hsla(219, 52%, 26%, 0.1);
  /* Sombra más extendida */
  margin-bottom: 30px;
  /* Espacio entre tarjetas */
  padding: 20px;
  /* Espacio interno */
  border-radius: 8px;
  /* Bordes redondeados */
}

.card_raw {
  margin-bottom: 50px;
  /* Espacio entre tarjetas */
  padding: 0 20px;
  /* Espacio interno */
}

.total-cost {
  font-weight: bold;
  margin-top: 40px;
  align-items: center;
  text-align: center;
  font-size: 1.1rem;
  background-color: #f7f9fc;
  padding: 15px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Adjust this as per your liking */
  margin-left: auto;
  margin-right: auto;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.mobile-column {
  display: flex;
  margin: 10px 0;
}

hr {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .api-cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .apis-view {
    padding: 20px;
  }

  h1 {
    margin-top: 0px;
  }

  .column {
    margin-left: 20px;
  }

  .total-cost {
    padding: 15px 30px;
    width: 40%;
  }

  .column.name {
    text-align: left;
  }

  .card_raw {
    margin-bottom: 10px;
    /* Espacio entre tarjetas */
    padding: 20px;
    /* Espacio interno */
  }
}
</style>
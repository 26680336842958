<template>
  <LoginComponent v-if="auth.route != 'authenticated'">
  </LoginComponent>

  <div v-else class="apis-view">
    <!-- Introducción -->
    <div class="card_raw">
      <section class="section">
        <h1>Hola, {{ auth.user?.attributes.email }}</h1>
        <p>
          En este dashboard podrá consultar su API token, así como ver su uso histórico de las APIs de pickgeo
        </p>
      </section>
    </div>
    <!-- Sección 1: APIs de Pickgeo -->
    <div class="card">
      <section class="section">
        <h1>Su API token</h1>
        <div @click="copyToClipboard">
          <n-tag type="success" class="wrap-token">
            <font-awesome-icon class="mr-5" icon="clipboard" /> {{ this.token.split('').join('\u200B') }}
          </n-tag>
        </div>
      </section>
    </div>
    <!-- Sección 1: APIs de Pickgeo -->
    <div class="card">
      <section class="section">
        <h1>Uso de las APIs</h1>
        <n-data-table :columns="this.columns" :data="this.usage" />
      </section>
    </div>
  </div>
</template>
  
<script>
import { usersApiService } from '../services/api.service'
import { useAuthenticator } from '@aws-amplify/ui-vue';
import LoginComponent from '../components/LoginComponent.vue'
import { useMessage } from 'naive-ui'

import {
  NTag,
  NDataTable
} from 'naive-ui'

export default {
  name: 'DashboardView',
  components: {
    LoginComponent,
    NTag,
    NDataTable
  },
  data() {
    const auth = useAuthenticator();
    const columns = [
      {
        title: "Mes",
        key: "name"
      },
      {
        title: "Llamadas",
        key: "requests"
      }
    ];
    return {
      auth: auth,
      columns: columns,
      token: '',
      usage: [],
    }
  },
  mounted() {
    this.message = useMessage();
    if (this.auth.route === 'authenticated') {
      (async () => {
        this.token = await usersApiService.userGetToken();
        this.usage = await usersApiService.userGetMonthlyUsage();
      })();
    }
  },
  watch: {
    async 'auth.route'(newRoute) {
      console.log(this.auth)
      if (newRoute === 'authenticated') {
        this.token = await usersApiService.userGetToken();
        this.usage = await usersApiService.userGetMonthlyUsage();
      }
    }
  },
  methods: {
    success(message) {
      this.message.success(
        message
      )
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.token);
      this.success('Token copiado al portapapeles!')
    }
  }
}
</script>

<style scoped>
.section {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.card {
  background-color: #ffffff;
  /* Fondo blanco */
  box-shadow: 0px 4px 50px 15px hsla(219, 52%, 26%, 0.1);
  /* Sombra más extendida */
  margin-bottom: 30px;
  /* Espacio entre tarjetas */
  padding: 20px;
  /* Espacio interno */
  border-radius: 8px;
  /* Bordes redondeados */
}

.card_raw {
  margin-bottom: 10px;
  /* Espacio entre tarjetas */
  padding: 20px;
  /* Espacio interno */
}

.wrap-token {
  /* Your custom styles here */
  max-width: 100%;
  height: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.1rem;
  padding: 10px 10px;
}

.wrap-token:hover {
  cursor: pointer;
}
</style>